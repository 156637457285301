import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Danish (Denmark) [da]
//! author : Per Hansen : https://github.com/perhp

export const daLocale: LocaleData = {
  abbr: 'da',
  months : 'Januar_Februar_Marts_April_Maj_Juni_Juli_August_September_Oktober_November_December'.split('_'),
  monthsShort : 'Jan_Feb_Mar_Apr_Maj_Jun_Jul_Aug_Sep_Okt_Nov_Dec'.split('_'),
  weekdays : 'Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag'.split('_'),
  weekdaysShort : 'Søn_Man_Tir_Ons_Tor_Fre_Lør'.split('_'),
  weekdaysMin : 'Sø_Ma_Ti_On_To_Fr_Lø'.split('_'),
  longDateFormat : {
      LT : 'HH:mm',
      LTS : 'HH:mm:ss',
      L : 'DD/MM/YYYY',
      LL : 'D. MMMM YYYY',
      LLL : 'D. MMMM YYYY HH:mm',
      LLLL : 'dddd [d.] D. MMMM YYYY [kl.] HH:mm'
  },
  calendar : {
      sameDay : '[i dag kl.] LT',
      nextDay : '[i morgen kl.] LT',
      nextWeek : 'på dddd [kl.] LT',
      lastDay : '[i går kl.] LT',
      lastWeek : '[i] dddd[s kl.] LT',
      sameElse : 'L'
  },
  relativeTime : {
      future : 'om %s',
      past : '%s siden',
      s : 'få sekunder',
      m : 'et minut',
      mm : '%d minutter',
      h : 'en time',
      hh : '%d timer',
      d : 'en dag',
      dd : '%d dage',
      M : 'en måned',
      MM : '%d måneder',
      y : 'et år',
      yy : '%d år'
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  ordinal : '%d.',
  week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4  // The week that contains Jan 4th is the first week of the year.
  }
};
